import React from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import ButtonV2 from "../../forms/ButtonV2";
import CONTENT from "./afp.resource";
import useAfp from "./useAfp";
import InputFieldV2 from "../../forms/InputFieldV2";
import FBIcon from "../../../assets/icons/whiteFB.svg";
import AddFacebookPagePrompt from "./afp.prompt";

export default function AddFacebookPageView() {
  const { showSuccess, isSubmitting, formik } = useAfp();
  return (
    <div className="relative overflow-y-auto min-h-screen max-w-screen-md container mx-auto scroll-smooth pt-[60px] pb-20">
      <Transition
        className="fixed -bottom-12 left-0 z-20 w-full"
        show={showSuccess}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <div className="fixed -bottom-12 left-0 z-20 w-full">
          <AddFacebookPagePrompt />
        </div>
      </Transition>
      <div className="flex flex-col justify-between items-center p-4 w-full h-full">
        <div className="flex flex-col justify-start items-center w-full space-y-[1.5rem] mb-10">
          <div className="flex flex-col justify-start items-center w-full space-y-[2.5rem]">
            {CONTENT.map((content) => (
              <div className="flex flex-col justify-start items-center w-full space-y-[0.63rem]">
                <img
                  src={content.icon}
                  alt="step illustration"
                  className="size-[2.875rem]"
                />
                <p className="text-[#667085] text-[0.875rem] font-semibold text-left  leading-[1.5rem]">
                  {content.description}
                </p>
              </div>
            ))}
          </div>
          <div className="w-full mt-10 flex flex-col justify-start space-y-[1rem]">
            <InputFieldV2
              id="facebook_page_id"
              label="Facebook Page ID"
              placeholder="Enter Facebook page ID"
              type="number"
              value={formik.values.facebook_page_id}
              onChange={formik.handleChange}
              error={formik.errors.facebook_page_id}
              touched={formik.touched.facebook_page_id}
            />
            <Link
              to="/account/connected-accounts/facebook/guide"
              className="self-end text-[0.875rem] text-right font-semibold text-[#4B1D8F]"
            >
              What is Facebook Page ID?
            </Link>
          </div>
        </div>
        <ButtonV2
          disabled={isSubmitting || !formik.isValid}
          onClick={formik.handleSubmit}
        >
          <div className="flex flex-row space-x-2 justify-center items-center">
            <img src={FBIcon} alt="facebook icon" className="mr-2" />
            <p>{isSubmitting ? "Connecting..." : "Connect account"}</p>
          </div>
        </ButtonV2>
      </div>
    </div>
  );
}
