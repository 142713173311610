import React from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import ConnectedAccountBulkSmsOptions from "./cbs.options";
import useCbs from "./useCbs";
import ButtonV2 from "../forms/ButtonV2";
import PromptsNoSenderId from "./prompts/prompts.noSenderId";

export default function ConnectBulkSmsView() {
  const navigate = useNavigate();
  const {
    handleBulkSmsProviderSelection,
    bulkSmsProviders,
    showNoSenderIdModal,
    setShowNoSenderIdModal,
    handleSetupSenderID,
  } = useCbs();
  return (
    <div className="relative overflow-y-auto h-screen max-w-screen-md container mx-auto scroll-smooth pt-[60px] pb-4">
      <Transition
        className="fixed -bottom-12 left-0 z-20 w-full"
        show={showNoSenderIdModal}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <div className="fixed -bottom-12 left-0 z-20 w-full">
          <PromptsNoSenderId
            setShowNoSenderIdModal={setShowNoSenderIdModal}
            handleSetupSenderID={handleSetupSenderID}
          />
        </div>
      </Transition>
      <div className="flex flex-col justify-between items-center p-4 w-full h-full">
        <div className="flex flex-col justify-start items-center w-full space-y-[1.5rem]">
          <p className="text-[#1A1A1A] text-[0.875rem] leading-[1.09375rem] text-center font-medium">
            Choose Your Bulk SMS Provider
          </p>
          <ConnectedAccountBulkSmsOptions
            handleBulkSmsProviderSelection={handleBulkSmsProviderSelection}
            bulkSmsProviders={bulkSmsProviders}
          />
        </div>
        <ButtonV2
          onClick={() => {
            if (bulkSmsProviders[1].selected) {
              setShowNoSenderIdModal(true);
            } else {
              navigate(
                `/account/connected-accounts/bulk-sms/add?provider=${
                  bulkSmsProviders.find((b) => b.selected).query
                }`
              );
            }
          }}
          disabled={
            bulkSmsProviders.every((b) => b.selected === false) ||
            bulkSmsProviders[0].selected
          }
        >
          Continue
        </ButtonV2>
      </div>
    </div>
  );
}
