import React from "react";
import PropTypes from "prop-types";
import facebook from "../../assets/icons/connected-accounts/facebook.svg";

const getStatus = (status) => {
  switch (status) {
    case "PARTNER_APPROVAL_PENDING":
      return { status: "Pending", color: "text-[#D6981B]" };
    case "PARTNER_REJECTED":
      return { status: "Rejected", color: "text-[#d61b1b]" };
    case "PARTNER_APPROVED":
      return { status: "Connected", color: "text-[#4B1D8F]" };
    default:
      return { status: "Failed", color: "text-[#d61b1b]" };
  }
};

export default function ConnectedFacebookAccount({ account }) {
  return (
    <div className="w-full flex flex-row space-x-[0.5rem] justify-start items-start option-shadow rounded-[0.75rem] p-[1rem]">
      <img
        src={facebook}
        alt="facebook icon"
        className="size-[1.25rem] object-contain mt-1"
      />
      <div className="flex flex-col space-y-[0.81rem] justify-start items-start">
        <p className="text-black text-[1rem] font-semibold">
          {account.name || account.fb_page_id}
        </p>
        <p
          className={`${
            getStatus(account?.partner_approval_status).color
          } text-[0.75rem]`}
        >
          {getStatus(account?.partner_approval_status)?.status}
        </p>
      </div>
    </div>
  );
}

ConnectedFacebookAccount.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    fb_page_id: PropTypes.number.isRequired,
    page_category: PropTypes.string.isRequired,
    merchant_id: PropTypes.number.isRequired,
    fb_page_link: PropTypes.string.isRequired,
    partner_approval_status: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
};
