import React from "react";
import PropTypes from "prop-types";
import ConnectedFacebookAccount from "./cf.account";

export default function ConnectedFacebookAccounts({ accounts }) {
  return (
    <div className="w-full h-full overflow-y-scroll flex flex-col justify-start items-center space-y-[1.5rem]">
      {accounts &&
        accounts?.map((account) => (
          <ConnectedFacebookAccount account={account} />
        ))}
    </div>
  );
}

ConnectedFacebookAccounts.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      fb_page_id: PropTypes.number.isRequired,
      page_category: PropTypes.string.isRequired,
      merchant_id: PropTypes.number.isRequired,
      fb_page_link: PropTypes.string.isRequired,
      partner_approval_status: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
};
