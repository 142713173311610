import React from "react";
import Layout from "../../../../components/layout/Layout";
import ConnectBulkSmsView from "../../../../components/connect-bulk-sms/cbs.view";

export default function ConnectBulkSmsPage() {
  return (
    <Layout
      header
      headerTitle="Set up your sender ID"
      backTo="/account/connected-accounts"
    >
      <ConnectBulkSmsView />
    </Layout>
  );
}
