import React from "react";
import PropTypes from "prop-types";
import ConnectAccountBulkSmsOption from "./cbs.option";

export default function ConnectedAccountBulkSmsOptions({
  handleBulkSmsProviderSelection,
  bulkSmsProviders,
}) {
  return (
    <div className="w-full h-full flex flex-col justify-start items-center space-y-[1.5rem]">
      {bulkSmsProviders.map((option) => (
        <ConnectAccountBulkSmsOption
          option={option}
          key={option.id}
          handleBulkSmsProviderSelection={handleBulkSmsProviderSelection}
        />
      ))}
    </div>
  );
}

ConnectedAccountBulkSmsOptions.propTypes = {
  handleBulkSmsProviderSelection: PropTypes.func.isRequired,
  bulkSmsProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      query: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
