/* eslint-disable react/jsx-props-no-spreading,react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import InputFieldV2 from "../forms/InputFieldV2";

export default function ConnectMpesaForm({ formik }) {
  return (
    <div className="w-full h-full flex flex-col justify-start items-center space-y-[1.5rem]">
      <InputFieldV2
        label="Buy Goods Till Number"
        id="till_number"
        type="number"
        placeholder="Enter your 10 digit Till number"
        error={formik.errors.till_number}
        touched={formik.touched.till_number}
        {...formik.getFieldProps("till_number")}
      />
      <InputFieldV2
        label="Store front number (Optional)"
        subtitle="The store number given to you by Safaricom"
        id="store_number"
        type="number"
        placeholder="e.g 321456"
        error={formik.errors.store_number}
        touched={formik.touched.store_number}
        {...formik.getFieldProps("store_number")}
      />
    </div>
  );
}

ConnectMpesaForm.propTypes = {
  formik: PropTypes.object.isRequired,
};
