import React from "react";
import { Transition } from "@headlessui/react";
import ButtonV2 from "../../forms/ButtonV2";
import useAsp from "./useAsp";
import AddProviderForm from "./asp.form";
import PromptsSuccess from "../prompts/prompts.success";

export default function AddSmsProviderView() {
  const { formik, showSuccess } = useAsp();

  return (
    <div className="relative h-screen max-w-screen-md container mx-auto scroll-smooth pt-[60px] pb-4">
      <Transition
        className="fixed -bottom-12 left-0 z-20 w-full"
        show={showSuccess}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <div className="fixed -bottom-12 left-0 z-20 w-full">
          <PromptsSuccess />
        </div>
      </Transition>
      <div className="flex flex-col justify-between items-center p-4 w-full h-full">
        <div className="flex flex-col justify-start items-center w-full space-y-[1.5rem]">
          <p className="text-[#1A1A1A] text-[0.875rem] leading-[1.09375rem] text-center font-medium">
            Please provide the necessary details for integrating your chosen
            bulk SMS provider.
          </p>
          <AddProviderForm formik={formik} />
        </div>
        <ButtonV2 onClick={formik.handleSubmit} disabled={!formik.isValid}>
          Continue
        </ButtonV2>
      </div>
    </div>
  );
}
