import React from "react";
import PropTypes from "prop-types";

export default function ConnectAccountBulkSmsOption({
  option,
  handleBulkSmsProviderSelection,
}) {
  return (
    <button
      type="button"
      onClick={() =>
        option.id === 1 ? null : handleBulkSmsProviderSelection(option.id)
      }
      className={`flex flex-row justify-between items-center p-[1rem] w-full rounded-[0.75rem] border-[1px] ${
        option.selected ? "border-[#4B1D8F]" : "border-[#D1D9DD]"
      }`}
    >
      <div className="w-full flex flex-row justify-start items-center space-x-[0.5rem]">
        <img
          src={option.icon}
          alt={option.name}
          className="h-[1.25rem] w-[1.25rem] object-contain"
        />
        <h3 className="text-[#1A1A1A] text-[1rem] font-bold">{option.name}</h3>
      </div>
      {option.id === 1 ? (
        <p className="bg-[#80C582] px-[0.31rem] py-[0.25rem] rounded-[0.19rem] text-white text-[0.5625rem]">
          DEFAULT
        </p>
      ) : (
        <input
          checked={option.selected}
          type="radio"
          className="accent-[#4B1D8F] border-[#D1D9DD] w-[1.375rem] h-[1.375rem]"
        />
      )}
    </button>
  );
}

ConnectAccountBulkSmsOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    query: PropTypes.string.isRequired,
  }).isRequired,
  handleBulkSmsProviderSelection: PropTypes.func.isRequired,
};
