import React from "react";
import ConnectedAccountOptions from "./ca.options";
import useCas from "./useCas";

export default function ConnectedAccountsView() {
  const { connectedAccounts } = useCas();
  return (
    <div className="flex flex-col justify-start items-center px-4 pt-4 pb-20 w-full h-full space-y-[1.5rem]">
      <p className="text-[#1A1A1A] text-[0.875rem] leading-[1.09375rem] text-center">
        Manage and monitor all the external accounts and platforms integrated
        with your tappi account.
      </p>
      <ConnectedAccountOptions options={connectedAccounts} />
    </div>
  );
}
