import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonV2 from "../../forms/ButtonV2";
import SuccessIcon from "../../../assets/success_check.svg";

export default function AddFacebookPagePrompt() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="fixed bottom-0 z-20 w-full h-screen flex items-center justify-center backdrop-blur bg-[#4B1D8F]/20">
        <div className="w-full max-w-screen-md pt-7 px-4 pb-9 rounded-t-[1rem] bg-white self-end flex flex-col justify-start items-center space-y-[1.25rem]">
          <img
            src={SuccessIcon}
            alt="success icon"
            className="w-[6.25rem] h-[6.25rem]"
          />
          <p className="text-[#1A1A1A] text-center text-[1.125rem] font-semibold">
            Success!
          </p>
          <p className="text-[#566370] text-center text-[0.875rem] font-medium px-7">
            Your Facebook page ID has been submitted successfully. An email will
            be sent to you soon.
          </p>
          <ButtonV2 onClick={() => navigate("/account/connected-accounts")}>
            Great
          </ButtonV2>
        </div>
      </div>
    </div>
  );
}
