import React from "react";
import PropTypes from "prop-types";

export default function ButtonV2({ children, onClick, disabled }) {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`w-full h-12 text-white thryve:text-base text-base font-semibold rounded-xl flex-shrink-0 ${
        disabled ? "bg-primary-30" : "bg-primary-50"
      } `}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

ButtonV2.defaultProps = {
  disabled: false,
};

ButtonV2.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
