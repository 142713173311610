import React from "react";
import Layout from "../../../../components/layout/Layout";
import AddFacebookPageGuideView from "../../../../components/connect-facebook/add-facebook-page-guide/afpg.view";

export default function ConnectFacebookGuidePage() {
  return (
    <Layout
      header
      headerTitle="Configure Connection"
      backTo="/account/connected-accounts/facebook/add"
    >
      <AddFacebookPageGuideView />
    </Layout>
  );
}
