/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import ShowPassword from "../../assets/icons/showPass.svg";

export default function InputFieldV2({
  label,
  subtitle,
  id,
  type,
  error,
  touched,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="w-full flex flex-col justify-start items-center">
      {label && (
        <label
          htmlFor={id}
          className="text-[0.875rem] font-medium text-black text-left w-full pb-1.5"
        >
          {label}
        </label>
      )}
      <div
        className={`w-full flex flex-col justify-start items-start px-[1rem] py-[0.56rem] space-y-[0.37rem] rounded-[0.5rem] ${
          touched && error
            ? "border-red-500 focus-within:ring-[0px] border-[2px] "
            : "border-[1px] focus-within:ring-[1px] focus-within:ring-[#4B1D8F] border-[#D1D9DD]"
        }  focus-within:outline-none `}
      >
        <div className="w-full flex flex-row justify-between items-center">
          <input
            type={type === "password" && showPassword ? "text" : type}
            id={id}
            className={`w-full ${
              label ? "h-[1.8rem]" : "h-[2.4rem]"
            }  text-[0.875rem] font-normal placeholder-[#566370]  border-none outline-none`}
            {...props}
          />
          {type === "password" && (
            <button
              type="button"
              onClick={() => setShowPassword((prevState) => !prevState)}
            >
              <img
                src={ShowPassword}
                alt="show password"
                className="w-[1.5rem] h-[1.5rem]"
              />
            </button>
          )}
        </div>
      </div>
      {subtitle && (
        <p className="text-[0.75rem] font-medium text-[#4A525A] text-left w-full pt-1.5">
          {subtitle}
        </p>
      )}
      {error && touched && (
        <p className="text-xs text-red-500 font-normal text-left w-full pt-1.5">
          {error}
        </p>
      )}
    </div>
  );
}

InputFieldV2.defaultProps = {
  type: "text",
  label: null,
  subtitle: null,
  error: null,
  touched: false,
};

InputFieldV2.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
};
