/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import LabelledInput from "../forms/LabelledInput";
import Button from "../forms/Button";
import BusinessProfileIcon from "../../assets/images/profile.png";
import PinLocationIcon from "../../assets/icons/google-maps-pin-icon.png";
import CustomDropZone from "../CustomDropZone";
import MerchantBanner from "../../assets/images/default-merchant-banner.png";
import UploadImageIcon from "../../assets/icons/upload-image-icon.png";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import {
  createSocialLinks,
  getCurrentMerchant,
  updateBusinessPhotos,
  updateMerchantDetails,
  updateSocialLinks,
  uploadDocument,
  uploadMerchantBusinessPhotos,
} from "../../utils/requests-v2";
import parseSocialMediaUsername from "../../utils/parseSocialUsernames";
import CustomPhoneInput from "../PhoneInput";
import { useAppContext } from "../../utils/Contexts/AppContext";
import resolveSubdomain from "../../utils/resolveSubdomain";
import {
  validateMpesaStoreNumber,
  validateMpesaTillNumber,
} from "../../utils/validators";

export default function ProfileForm({ industries }) {
  const { user, updateUser } = useAuth();
  const [validationError, setValidationError] = useState("");

  const [subDomain, setSubDomain] = useState("");
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 5000000,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "application/pdf": [".pdf"],
    },
  });
  const [phoneNumber, setPhoneNumber] = useState(`+${user?.phone_number}`);
  const [phoneNumberErrors, setPhoneNumberErrors] = useState({});

  const [coverPhotoSet, setCoverPhotoSet] = useState(false);
  const [profilePhotoSet, setProfilePhotoSet] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const { appVendor } = useAppContext();

  // Automatically set default subdomain
  useEffect(() => {
    if (user) {
      const url = resolveSubdomain(appVendor, user);
      setSubDomain(url);
    }
  }, [user]);

  const {
    register,
    handleSubmit,
    formState: { dirtyFields, defaultValues, errors },
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      business_name: user?.business_name,
      full_name: user?.full_name,
      email: user?.email_address,
      phone_number: phoneNumber,
      till_number: user?.till_number,
      store_number: user?.store_number,
      industry_id: user?.industry?.id,
      business_description: user?.business_description || "",
      location: user?.location_info?.name || user?.state || "",
      whatsapp_number: user?.whatsapp_number,
      instagram: parseSocialMediaUsername(user?.social_links?.instagram || ""),
      facebook: parseSocialMediaUsername(user?.social_links?.facebook || ""),
      website: user?.social_links?.website || "",
    },
  });

  // Register the website field with validation
  register("facebook", {
    validate: (value) => {
      if (
        value &&
        !/^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)
      ) {
        return "Facebook Profile URL must start with https e.g https://facebook.com/username";
      }
      return true;
    },
  });

  register("instagram", {
    validate: (value) => {
      if (
        value &&
        !/^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)
      ) {
        return "Instagram Profile URL must start with https e.g https://instagram.com/username";
      }
      return true;
    },
  });

  register("website", {
    validate: (value) => {
      if (
        value &&
        !/^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)
      ) {
        return "Website must start with https and have a valid domain extension";
      }
      return true;
    },
  });

  const [bannerImage, setBannerImage] = useState("");
  const [logoImage, setLogoImage] = useState("");

  const [coverPhotoToDisplay, setCoverPhotoToDisplay] = useState(
    user?.cover_image_url
  );

  const navigate = useNavigate();

  const redirectTimeOut = () =>
    setTimeout(() => {
      navigate("/account");
    }, 2000);

  // Clear timeout if component is unmounted
  useEffect(() => () => clearTimeout(redirectTimeOut), []);

  // This effect runs to determine which cover_photo is displayed to the user
  useEffect(() => {
    if (bannerImage) {
      setCoverPhotoToDisplay(URL.createObjectURL(bannerImage));
    } else if (user?.cover_image_url) {
      setCoverPhotoSet(true);
      setCoverPhotoToDisplay(user.cover_image_url);
    } else {
      setCoverPhotoSet(false);
      setCoverPhotoToDisplay(MerchantBanner);
    }
  }, [bannerImage, user.merchant]);

  const [logoPhotoToDisplay, setLogoPhotoToDisplay] = useState(
    user?.profile_photo_url
  );

  // This effect determines which profile_photo is displayed to the user
  useEffect(() => {
    if (logoImage) {
      setLogoPhotoToDisplay(URL.createObjectURL(logoImage));
    } else if (user?.profile_photo_url) {
      setProfilePhotoSet(true);
      setLogoPhotoToDisplay(user?.profile_photo_url);
    } else {
      setProfilePhotoSet(false);
      setLogoPhotoToDisplay(BusinessProfileIcon);
    }
  }, [logoImage, user]);

  const handleBlur = (event) => {
    const value = event.target.value.trim().replace(/\s+/g, " ");
    if (value.length < 30) {
      setError("business_description", {
        message: "Description must be at least 30 characters long",
      });
      setValidationError("Description must be at least 30 characters long");
    } else {
      setValidationError("");
    }
  };

  async function onSubmit(data) {
    // We only want to update values that have changed
    const payload = Object.keys(dirtyFields).reduce((acc, curr) => {
      if (getValues(curr) !== defaultValues[curr]) {
        return { ...acc, [curr]: data[curr] };
      }
      return { ...acc };
    }, {});

    if (payload.till_number) {
      if (!validateMpesaTillNumber(payload.till_number)) {
        setError("till_number", {
          message: "Invalid Till Number",
        });
        return;
      }
    }

    if (payload.store_number) {
      if (!validateMpesaStoreNumber(payload.store_number)) {
        setError("store_number", {
          message: "Invalid Store Number",
        });
        return;
      }
    }

    if (validationError) {
      setError("business_description", {
        message: "Description must be at least 30 characters long",
      });
      setValidationError("Description must be at least 30 characters long");
      return;
    }

    try {
      // Multiple requests will be resolved at once
      const requests = [];

      if (bannerImage || logoImage) {
        // If the merchant hadn't previously set either profile or cover photo, we proceed to upload them
        if (!profilePhotoSet || !coverPhotoSet) {
          requests.push(
            uploadMerchantBusinessPhotos({
              coverPhoto: bannerImage,
              profilePhoto: logoImage,
              merchantID: user?.id,
            })
          );
        } else {
          // If the merchant had previously set either cover photo or profile photo, we proceed to update them
          requests.push(
            updateBusinessPhotos({
              cover_photo: bannerImage,
              profile_photo: logoImage,
              merchantID: user?.id,
            })
          );
        }
      }

      // First we check if social links have been updated since they should be updated on a separate endpoint
      if (Object.keys(payload).length) {
        const socialLinkKeys = ["facebook", "instagram", "website"];
        const socialsData = {
          facebook: payload.facebook,
          instagram: payload.instagram,
          website: payload.website,
        };
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(payload)) {
          if (socialLinkKeys.includes(key)) {
            // If the socials_link has an id, it means it already exists, and we need to update
            if (user.social_links.id) {
              requests.push(updateSocialLinks({ data: socialsData }));
              delete payload.facebook;
              delete payload.instagram;
              delete payload.website;
              break;
            } else {
              // Otherwise we need to create social links
              requests.push(createSocialLinks({ data: socialsData }));
              delete payload.facebook;
              delete payload.instagram;
              delete payload.website;
              break;
            }
          }
        }
      }

      // Now we push all other updated data to the requests
      if (Object.keys(payload).length) {
        requests.push(updateMerchantDetails(user.id, payload));
      }

      if (acceptedFiles.length) {
        requests.push(
          uploadDocument({
            name: acceptedFiles[0].name,
            document: acceptedFiles[0],
          })
        );
      }

      // Only make API requests if there are changes to the form
      if (requests.length) {
        setIsUpdating(true);
        await Promise.all(requests);

        const merchantData = await getCurrentMerchant(user.id);

        updateUser(merchantData);

        setIsUpdating(false);

        toast.success("Your profile was updated!");

        redirectTimeOut();
      } else {
        toast.error("You have not made any changes");
      }
    } catch (error) {
      setIsUpdating(false);
      toast.error("Something went wrong. Please try again.");
      console.log(error);
    } finally {
      setIsUpdating(false);
    }
  }

  return (
    <div>
      {user ? (
        <div>
          <fieldset disabled={isUpdating} aria-busy={isUpdating}>
            <div className="w-full relative">
              <div className="relative">
                <input
                  type="file"
                  id="businessBannerImageInput"
                  hidden
                  onChange={(e) => setBannerImage(e.target.files[0])}
                  name="businessBannerImage"
                  accept="image/*"
                />
                <label
                  htmlFor="businessBannerImageInput"
                  className="bg-[#1A1A1A] h-[32px] w-[32px] flex items-center justify-center rounded-full hover:cursor-pointer absolute top-[37.5%] left-[50%]"
                >
                  <img
                    src={UploadImageIcon}
                    alt="Upload Banner"
                    className="h-[16px] w-[16px]"
                    aria-label="Upload Business Banner"
                    title="Upload Business Banner"
                  />
                </label>

                <img
                  src={coverPhotoToDisplay}
                  alt="Banner"
                  className="w-full h-36 object-cover"
                />
              </div>
              <div className="relative w-max">
                <input
                  type="file"
                  id="businessLogoImageInput"
                  hidden
                  onChange={(e) => setLogoImage(e.target.files[0])}
                  name="businessBannerImage"
                  accept="image/*"
                />
                <label
                  htmlFor="businessLogoImageInput"
                  className="bg-[#1A1A1A] h-[32px] w-[32px] flex items-center justify-center rounded-full hover:cursor-pointer absolute bottom-[-8px] right-[-10px] z-[1]"
                >
                  <img
                    src={UploadImageIcon}
                    alt="Upload Logo"
                    className="h-[16px] w-[16px]"
                    aria-label="Upload Business Logo"
                    title="Upload Business Logo"
                  />
                </label>

                <img
                  src={logoPhotoToDisplay}
                  alt="Business Logo"
                  className="mt-[-42px] ml-8 h-[84px] w-[84px] relative rounded-lg border-2 border-[#F9F6F2]"
                />
              </div>
            </div>
          </fieldset>

          <section className="mt-12 px-4">
            <fieldset disabled={isUpdating} aria-busy={isUpdating}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className="font-medium my-2">Business Information</h3>

                <div className="flex justify-center">
                  <div className="w-full">
                    <LabelledInput
                      id="businessNameInput"
                      label="Business Name"
                      name="business_name"
                      register={register}
                      rules={{ required: true }}
                    />

                    <LabelledInput
                      id="fullNameInput"
                      label="Full Name"
                      name="full_name"
                      register={register}
                    />

                    <LabelledInput
                      id="subDomainInput"
                      label="Website"
                      name="subdomain"
                      readOnly
                      value={subDomain}
                    />

                    <CustomPhoneInput
                      phoneNumber={phoneNumber}
                      selectedCountryCode={user?.country?.country_code}
                      setPhoneNumber={setPhoneNumber}
                      setErrors={setPhoneNumberErrors}
                      setSelectedCountryCode={() => {}} // do nothing
                      countries={[user.country?.country_code]} // User cannot change country code
                      errors={phoneNumberErrors}
                      disabled
                      className="my-4 md"
                    />

                    <LabelledInput
                      id="emailInput"
                      label="Email"
                      name="email"
                      type="email"
                      register={register}
                    />

                    {/* {user?.country?.country_code === "KE" && ( */}
                    {/*  <> */}
                    {/*    <LabelledInput */}
                    {/*      id="tillNumberInput" */}
                    {/*      label="Till Number" */}
                    {/*      name="till_number" */}
                    {/*      register={register} */}
                    {/*      errorText={errors.till_number?.message} */}
                    {/*    /> */}

                    {/*    <LabelledInput */}
                    {/*      id="storeNumberInput" */}
                    {/*      label="Store Number" */}
                    {/*      name="store_number" */}
                    {/*      register={register} */}
                    {/*      errorText={errors.store_number?.message} */}
                    {/*    /> */}
                    {/*  </> */}
                    {/* )} */}

                    <div className="mb-3 w-full">
                      {industries && (
                        <select
                          className="form-select appearance-none block w-full h-[58px] px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-40 focus:outline-none focus:shadow "
                          aria-label="Select Industry"
                          required
                          {...register("industry_id")}
                        >
                          <option value="">Category / Industry</option>
                          {industries.map((industry) => (
                            <option key={industry.id} value={industry.id}>
                              {industry.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>

                    <div className="mb-3 w-full">
                      <textarea
                        className="form-control block w-full h-auto px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-50 focus:outline-none focus:shadow"
                        id="businessDescriptionInput"
                        rows="3"
                        placeholder="Provide A Brief Description (min 30 characters)"
                        {...register("business_description")}
                        minLength={30}
                        onBlur={handleBlur}
                      />
                      {validationError && (
                        <p className="text-red-500">{validationError}</p>
                      )}
                    </div>

                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-300 focus:shadow-none focus:outline-none"
                        placeholder="Address"
                        aria-label="Address"
                        aria-describedby="location-select"
                        id="addressInput"
                        {...register("location")}
                        disabled
                      />
                      <button
                        className="input-group-text flex items-center py-[18px] h-14"
                        id="location-select"
                        type="button"
                        tabIndex={-1}
                        style={{ paddingLeft: "10px" }}
                        onClick={() => navigate("/account/profile/location")}
                        role="link"
                      >
                        <img
                          src={PinLocationIcon}
                          alt="location"
                          style={{ height: 14, width: 12 }}
                        />
                        <span className="ml-2 text-sm font-normal text-green-50">
                          Use map
                        </span>
                      </button>
                    </div>

                    <LabelledInput
                      id="whatAppBusinessNumberInput"
                      name="whatsapp_number"
                      register={register}
                      type="tel"
                      label="WhatsApp Business Number"
                    />

                    <CustomDropZone
                      getInputProps={getInputProps}
                      getRootProps={getRootProps}
                      acceptedFiles={acceptedFiles}
                      label="Upload pricelist/menu"
                      className="dropzone my-6"
                      showSelectedFiles
                    />

                    <hr className="my-4" />

                    <h3 className="font-medium my-2 mt-4">Social Links</h3>

                    <LabelledInput
                      type="text"
                      id="instagramUsername"
                      register={register}
                      label="Instagram Profile URL"
                      name="instagram"
                      errorText={errors.instagram?.message}
                    />

                    <LabelledInput
                      type="text"
                      id="facebookUsername"
                      register={register}
                      label="Facebook Profile URL"
                      name="facebook"
                      errorText={errors.facebook?.message}
                    />

                    <LabelledInput
                      type="text"
                      id="businessWebsiteInput"
                      register={register}
                      label="Website"
                      name="website"
                      errorText={errors.website?.message}
                    />

                    <hr className="my-6" />

                    <Button
                      href="/account/profile/working-hours"
                      variant="outline"
                      className="w-full my-4 flex items-center justify-between"
                    >
                      <span>Update Operation Hours</span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.995 0C2.235 0 0 2.24 0 5C0 7.76 2.235 10 4.995 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 4.995 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9ZM4.5 2.5H5.25V5.125L7.5 6.46L7.125 7.075L4.5 5.5V2.5Z"
                          fill="#A0A3AD"
                        />
                      </svg>
                    </Button>

                    <div className="text-center lg:text-left">
                      <Button type="submit" isLoading={isUpdating}>
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </section>
        </div>
      ) : (
        <div>...loading...</div>
      )}
    </div>
  );
}

ProfileForm.propTypes = {
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
};
