import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { updateMerchantDetails } from "../../utils/requests-v2";

const useCa = () => {
  const { user, updateUser } = useAuth();
  const [isSubmitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      till_number: user?.till_number,
      store_number: user?.store_number,
    },
    validationSchema: Yup.object({
      till_number: Yup.number().required("This is field is required"),
      store_number: Yup.number().required("This is field is required"),
    }),
    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        const response = await updateMerchantDetails(user.id, values);
        if (response?.id) {
          updateUser(response);
          setShowSuccess(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return { isSubmitting, formik, setSubmitting, showSuccess, setShowSuccess };
};

export default useCa;
