function formatPhoneNumber(phoneNumber) {
  // Check if the phone number starts with '0'
  if (phoneNumber.startsWith("0")) {
    // Remove the leading '0' and replace it with '254'
    return `254${phoneNumber.slice(1)}`;
  }
  // Check if the phone number starts with '254'
  if (phoneNumber.startsWith("254")) {
    // Return the number as is
    return phoneNumber;
  }

  return phoneNumber;
}

export default formatPhoneNumber;
