import React, { useState, useCallback, useEffect } from "react";
import { useStateMachine } from "little-state-machine";
import Modal from "react-modal";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

import Layout from "../../components/layout/V2/Layout";
import FacebookAdPreview from "../../components/V2/FacebookAdPreview";
import { updateState, resetStoreValue } from "../../utils/stateMachine";
import FacebookAdsAudienceModal from "../../components/FacebookAdsAudienceModal";
import FacebookAdsAudienceDetails from "../../components/FacebookAdsAudienceDetails";
import FacebookAdsDurationDetails from "../../components/FacebookAdsDurationDetails";
import FacebookAdsDurationModal from "../../components/FacebookAdsDurationModal.";
import Button from "../../components/forms/Button";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import calculateAdTotal from "../../utils/calculateAdTotal";
import {
  PAYMENT_REASONS,
  usePaymentContext,
} from "../../utils/Contexts/V2/PaymentContext2";
import {
  createFacebookAd,
  updateFacebookAd,
  uploadFacebookAdImage,
} from "../../utils/requests-v2";
import dataURLtoFile from "../../utils/dataUrlToFile";
import { useAppContext } from "../../utils/Contexts/AppContext";
import { useBusinessContext } from "../../utils/Contexts/V2/BusinessContext";

const modalStyles = {
  content: {
    inset: "auto 0 0 0",
    borderRadius: "16px 16px 0 0",
  },
  overlay: {
    backgroundColor: "#00000033",
  },
};

export default function AdPreviewPage() {
  const { state, actions } = useStateMachine({ updateState, resetStoreValue });
  const { setActivePaymentAmount, setActivePaymentFor } = usePaymentContext();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  const openModal = useCallback((modalId) => {
    setModalIsOpen(true);
    setVisibleModal(modalId);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setVisibleModal(null);
  });

  const { user } = useAuth();
  const { appTheme } = useAppContext();

  const [amountToPay, setAmountToPay] = useState(0);

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (state.adJourney.stepsDone !== 3) {
      navigate("/ads/create");
    }
  }, []);

  const queryClient = useQueryClient();

  const [adPrice, setAdPrice] = useState(1000);

  const {
    business: {
      connectedFacebookPages: {
        data: connectedFacebookPages,
        isLoading: isLoadingFacebookPages,
      },
    },
  } = useBusinessContext();

  useEffect(() => {
    if (user?.country.country_code === "KE") {
      setAdPrice(300);
    }
  }, [user]);

  useEffect(() => {
    if (state.adJourney.durationData.days) {
      const amount = calculateAdTotal(
        state.adJourney.durationData.days,
        adPrice
      );
      setAmountToPay(amount);
      setActivePaymentAmount(amount);
      setActivePaymentFor(PAYMENT_REASONS.ADS);
    }
  }, [state.adJourney.durationData.days, adPrice]);

  const redirectTimeout = () => setTimeout(() => navigate("/ads"), 2000);

  useEffect(() => () => clearTimeout(redirectTimeout), []);

  useEffect(() => {
    if (connectedFacebookPages.length) {
      actions.updateState({
        adJourney: {
          ...state.adJourney,
          facebookPageId: connectedFacebookPages[0].id,
        },
      });
    }
  }, [connectedFacebookPages]);

  const saveDraftAd = useCallback(() => {
    const adsData = {
      header_text: state.adJourney.headline,
      body_text: state.adJourney.description,
      duration: state.adJourney.durationData.days.toString(),
      start_date:
        typeof state.adJourney.durationData.startDate === "string"
          ? moment(state.adJourney.durationData.startDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : state.adJourney.durationData.startDate.format(
              "YYYY-MM-DD HH:mm:ss"
            ),
      end_date:
        typeof state.adJourney.durationData.endDate === "string"
          ? moment(state.adJourney.durationData.endDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : state.adJourney.durationData.endDate.format("YYYY-MM-DD HH:mm:ss"),
      is_draft: true,
      age_max: state.adJourney.audienceData.endAge.toString(),
      age_min: state.adJourney.audienceData.startAge.toString(),
      gender: state.adJourney.audienceData.gender,
      whatsapp_number: user.whatsapp_number,
      location: state.adJourney.audienceData.location.key || null,
      ad_type: 1,
      url: state.adJourney.url,
      ...(state.adJourney.instagramUsername && {
        instagram_url: `https://instagram.com/${state.adJourney.instagramUsername}`,
      }),
      ai_goal: state.adJourney.audienceData.goal,
      ai_target_audience: state.adJourney.audienceData.interests,
      facebook_page_id: state.adJourney.facebookPageId,
    };

    setIsSubmitting(true);

    // This means the ad was already created as draft and it has an id
    // So we update existing draft instead of creating a new draft ad
    if (state.adJourney.isEditing && state.adJourney.id) {
      updateFacebookAd({ id: state.adJourney.id, data: adsData })
        .then(async () => {
          await queryClient.refetchQueries({
            queryKey: ["/facebook-ads"],
            type: "all",
          });

          actions.resetStoreValue("adJourney");
          toast.success("Your Draft Ad Was Successfully Updated");
          redirectTimeout();
        })
        .catch((error) => {
          console.error("Could not update draft ad : ", error);
          toast.error("Could not update draft ad. Please try again");
        })
        .finally(() => setIsSubmitting(false));
    } else {
      createFacebookAd({ data: adsData })
        .then(async (response) => {
          const imageFiles = [];

          imageFiles.push(
            dataURLtoFile(state.adJourney.image, `${crypto.randomUUID()}`)
          );

          const requests = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const image of imageFiles) {
            requests.push(uploadFacebookAdImage({ image, id: response.id }));
          }

          await Promise.all(requests);
        })
        .then(async () => {
          await queryClient.refetchQueries({
            queryKey: ["/facebook-ads"],
            type: "all",
          });

          actions.resetStoreValue("adJourney");
          toast.success("Your Ad Was Successfully Created As A Draft");
          redirectTimeout();
        })
        .catch((error) => {
          console.error("Could not save draft ad : ", error);
          toast.error("Could not save draft ad. Please try again");
        })
        .finally(() => setIsSubmitting(false));
    }
  }, []);

  const discardDraftAd = useCallback(() => {
    actions.resetStoreValue("adJourney");
    closeModal();
    navigate("/ads/create");
  }, []);

  return (
    <Layout
      header
      headerTitle={
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-normal text-gray-500 text-center">
            Create Ad
          </p>
          <h2 className="text-base">Design Your Ad</h2>
        </div>
      }
    >
      <div className="pt-20 max-w-screen-xl container mx-auto">
        <div className="my-4">
          <div className="h-1 w-[70vw] bg-gray-200 dark:bg-gray-300 mx-auto">
            <div
              className="h-1 bg-purple-900 thryve:bg-primary-50"
              style={{ width: "75%" }}
            />
          </div>
        </div>
        <p className="text-[#1A1A1A] text-sm text-center px-4">
          Please look over your ad to ensure everything is the way you want it
        </p>

        <div className="my-4 px-2 space-y-4">
          <FacebookAdPreview
            image={
              (state?.adJourney?.images?.length && state.adJourney.images[0]) ||
              ""
            }
            editable={false}
            description={state?.adJourney?.description}
            url={state?.adJourney?.url}
          />

          <FacebookAdsAudienceDetails openModal={openModal} />

          <FacebookAdsDurationDetails openModal={openModal} />

          {/* Only allow users to select a Facebook page if they've connected a Facebook page */}
          <div className="border rounded-lg px-4 py-2">
            <p className="font-semibold">Facebook Page</p>
            {
              // eslint-disable-next-line no-nested-ternary
              connectedFacebookPages && connectedFacebookPages.length > 0 ? (
                <select
                  name="facebook_page_id"
                  id="facebookPageInput"
                  onChange={(e) => {
                    const fbPage = connectedFacebookPages.filter(
                      (page) => page.id === e.target.value
                    )[0];
                    actions.updateState({
                      adJourney: {
                        ...state.adJourney,
                        facebookPageId: fbPage.id,
                      },
                    });
                  }}
                  className="form-select appearance-none block w-max h-[48px] px-3 pr-8 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-40 focus:outline-none focus:shadow mt-2"
                  value={state?.adJourney?.facebookPageId}
                >
                  {connectedFacebookPages?.map((page) => (
                    <option
                      key={page.id}
                      value={page.id}
                      selected={page.id === state?.adJourney.facebookPageId}
                    >
                      {page.name}
                    </option>
                  ))}
                </select>
              ) : isLoadingFacebookPages ? (
                <div>Loading</div>
              ) : (
                <div>
                  <p>Your Ad Will be Posted to the default Facebook Page</p>
                  <Button href="/account/connect-facebook" className="w-max">
                    Connect Facebook Account
                  </Button>
                </div>
              )
            }
          </div>

          <div className="border rounded-lg px-4 py-2">
            <p className="font-semibold">Payment Summary</p>
            <div className="flex items-start justify-between mt-2">
              <div className="text-xs w-8/12">
                <p className="font-semibold">Total Budget</p>
                <p>
                  {user?.merchant?.country_name?.currency} {adPrice} a day X{" "}
                  {state?.adJourney?.durationData?.days} Days to get{" "}
                  {1000 * state.adJourney.durationData.days} reach
                </p>
              </div>
              <p className="text-sm font-semibold">
                {user?.country.currency_code} {amountToPay}
              </p>
            </div>
          </div>

          <div className="pt-6 space-y-4">
            <Button
              className="flex justify-center items-center"
              href="/ads/create/payment"
            >
              Proceed To Pay
            </Button>
            <Button
              variant="outline"
              className="w-full"
              onClick={() => {
                setVisibleModal("confirm-draft-modal");
                setModalIsOpen(true);
              }}
            >
              Cancel
            </Button>
          </div>

          <FacebookAdsAudienceModal
            closeModal={closeModal}
            modalIsOpen={modalIsOpen && visibleModal === "audience-modal"}
            modalStyles={modalStyles}
          />

          <FacebookAdsDurationModal
            closeModal={closeModal}
            modalIsOpen={modalIsOpen && visibleModal === "duration-modal"}
            modalStyles={modalStyles}
          />

          <Modal
            style={modalStyles}
            isOpen={modalIsOpen && visibleModal === "confirm-draft-modal"}
            onRequestClose={() => {
              setModalIsOpen(false);
              setVisibleModal(null);
            }}
            shouldCloseOnOverlayClick
            id="confirm-draft-modal"
            contentLabel="Confirm Draft Modal"
          >
            <form className={appTheme}>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <div className="flex justify-between items-center">
                  <h2>Save Draft?</h2>
                  <button type="button" onClick={closeModal}>
                    <svg
                      viewBox="0 0 1024 1024"
                      fill="currentColor"
                      height="1.5em"
                      width="1.5em"
                    >
                      <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
                      <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                    </svg>
                  </button>
                </div>
                <p className="my-6 text-center">
                  {state.adJourney.isEditing
                    ? "Would you like to update changes to your draft ad?"
                    : "Would you like to save this ad as a draft and come back to it later?"}
                </p>

                <div className="my-6 space-y-2">
                  <Button
                    type="button"
                    onClick={saveDraftAd}
                    className="thryve:text-[#1A1A1A] tappi:text-white"
                  >
                    {state.adJourney.isEditing
                      ? "Yes, Update Draft"
                      : "Yes, Save"}
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    className="w-full"
                    onClick={discardDraftAd}
                  >
                    Discard
                  </Button>
                </div>
              </fieldset>
            </form>
          </Modal>
        </div>
      </div>
    </Layout>
  );
}
