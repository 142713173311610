import React from "react";
import Layout from "../../../components/layout/Layout";
import ConnectMpesaView from "../../../components/connect-mpesa/ca.view";

export default function ConnectMpesaPage() {
  return (
    <Layout
      header
      headerTitle="My M-Pesa Buy Goods Details"
      backTo="/account/connected-accounts"
    >
      <ConnectMpesaView />
    </Layout>
  );
}
