import React from "react";
import { Transition } from "@headlessui/react";
import ButtonV2 from "../forms/ButtonV2";
import useCa from "./useCa";
import ConnectMpesaForm from "./ca.form";
import PromptsSuccess from "../connect-bulk-sms/prompts/prompts.success";

export default function ConnectMpesaView() {
  const { formik, showSuccess, isSubmitting } = useCa();
  return (
    <div className="relative h-screen max-w-screen-md container mx-auto scroll-smooth pt-[60px] pb-4">
      <Transition
        className="fixed -bottom-12 left-0 z-20 w-full"
        show={showSuccess}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <div className="fixed -bottom-12 left-0 z-20 w-full">
          <PromptsSuccess />
        </div>
      </Transition>
      <div className="flex flex-col justify-between items-center p-4 w-full h-full">
        <div className="flex flex-col justify-start items-center w-full space-y-[1.5rem]">
          <p className="text-[#1A1A1A] text-[0.875rem] leading-[1.09375rem] text-center font-medium">
            Your details are only used to receive payment from your customers.
            It won’t be used for <span className="font-semibold">ANYTHING</span>{" "}
            else.
          </p>
          <ConnectMpesaForm formik={formik} />
        </div>
        <ButtonV2
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || isSubmitting}
        >
          {isSubmitting ? "Updating..." : "Update"}
        </ButtonV2>
      </div>
    </div>
  );
}
