import React from "react";
import { LoaderIcon } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ButtonV2 from "../forms/ButtonV2";
import useFb from "./useFb";
import ConnectedFacebookAccounts from "./cf.accounts";

export default function ConnectFBView() {
  const navigate = useNavigate();
  const { fbPages, fetchingFbPages } = useFb();
  return (
    <div className="relative overflow-y-auto h-screen max-w-screen-md container mx-auto scroll-smooth pt-[60px] pb-4">
      <div className="flex flex-col justify-between items-center p-4 w-full h-full">
        <div className="flex flex-col justify-start items-center w-full space-y-[1.5rem] h-[90%]">
          <p className="text-[#1A1A1A] text-[0.875rem] leading-[1.09375rem] text-center font-medium">
            Tap on any of the page(s) below to disconnect it from your tappi
            account.
          </p>
          <p className="text-black text-[1rem] tet-left w-full font-semibold">
            Connected page(s)
          </p>
          {fetchingFbPages ? (
            <LoaderIcon />
          ) : (
            <ConnectedFacebookAccounts accounts={fbPages} />
          )}
        </div>
        <ButtonV2
          onClick={() => navigate("/account/connected-accounts/facebook/add")}
        >
          Add new Facebook page
        </ButtonV2>
      </div>
    </div>
  );
}
