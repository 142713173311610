import React from "react";
import ConnectedAccountsView from "../../../components/connected-accounts/ca.view";
import Layout from "../../../components/layout/Layout";

export default function ConnectedAccountsPage() {
  return (
    <Layout header headerTitle="Connected Accounts" backTo="/account">
      <div className="relative overflow-y-auto min-h-screen max-w-screen-md container mx-auto scroll-smooth pt-[60px] pb-4">
        <div className="w-full">
          <ConnectedAccountsView />
        </div>
      </div>
    </Layout>
  );
}
