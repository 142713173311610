import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import chevronRight from "../../assets/icons/connected-accounts/chevronRight.svg";

export default function ConnectedAccountOption({ option }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-start items-start p-[1rem] w-full rounded-[0.75rem] option-shadow space-y-[0.62rem]">
      <div className="w-full flex flex-row justify-start items-center space-x-[0.5rem]">
        <img
          src={option.icon}
          alt={option.name}
          className="size-[1.25rem] object-contain"
        />
        <h3 className="text-[#1A1A1A] text-[1rem] font-bold">{option.name}</h3>
      </div>
      <p className="text-[#1A1A1A] text-[0.875rem] leading-[1.25rem]">
        {option.description}
      </p>
      <div className="flex flex-row justify-between items-center w-full">
        <p
          className={`${
            option.status ? "text-[#12B76A]" : "text-[#F04438]"
          } text-[0.75rem] font-bold`}
        >
          {option.status ? "Connected" : "Not Connected"}
        </p>
        <button
          type="button"
          onClick={() => navigate(option.link)}
          className="flex flex-row justify-start items-center space-x-[0.5rem]"
        >
          <p className="text-[0.75rem] font-bold text-[#4B1D8F]">Configure</p>
          <img
            src={chevronRight}
            alt="configure"
            className="size-[0.625rem] object-contain"
          />
        </button>
      </div>
    </div>
  );
}

ConnectedAccountOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};
