import React from "react";
import Layout from "../../../../components/layout/Layout";
import ConnectFBView from "../../../../components/connect-facebook/cf.view";

export default function ConnectFacebookPage() {
  return (
    <Layout
      header
      headerTitle="Configure Connection"
      backTo="/account/connected-accounts"
    >
      <ConnectFBView />
    </Layout>
  );
}
