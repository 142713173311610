import React from "react";
import PropTypes from "prop-types";
import NoSenderID from "../../../assets/icons/connected-accounts/no_sender_id_prompt_icon.svg";
import ButtonV2 from "../../forms/ButtonV2";

export default function PromptsNoSenderId({
  setShowNoSenderIdModal,
  handleSetupSenderID,
}) {
  return (
    <div>
      <div className="fixed bottom-0 z-20 w-full h-screen flex items-center justify-center backdrop-blur bg-[#4B1D8F]/20">
        <div className="w-full max-w-screen-md pt-7 px-4 pb-9 rounded-t-[1rem] bg-white self-end flex flex-col justify-start items-center space-y-[1.25rem]">
          <p className="text-[#1A1A1A] text-center text-[1.125rem] font-bold">
            Set up your sender ID
          </p>
          <img
            src={NoSenderID}
            alt="no sender id icon"
            className="w-[3rem] h-[3rem]"
          />
          <p className="text-[#1A1A1A] text-center text-[0.875rem] leading-[1.25rem]">
            A Sender ID is a custom name or number that appears as the sender of
            your messages. It adds credibility to your messages and helps
            recipients recognize your business.
          </p>
          <p className="text-[#1A1A1A] text-center text-[0.875rem] leading-[1.25rem]">
            To ensure a smooth experience with our Bulk SMS feature, kindly
            reach out to us to assist you set up your Sender ID first.
          </p>
          <ButtonV2 onClick={handleSetupSenderID}>
            Contact tappi support
          </ButtonV2>
          <button
            onClick={() => setShowNoSenderIdModal(false)}
            type="button"
            className="text-[#1A1A1A] text-center text-[1rem] font-semibold"
          >
            Maybe later
          </button>
        </div>
      </div>
    </div>
  );
}

PromptsNoSenderId.propTypes = {
  setShowNoSenderIdModal: PropTypes.func.isRequired,
  handleSetupSenderID: PropTypes.func.isRequired,
};
