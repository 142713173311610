const modalStyles = {
  content: {
    inset: "auto 0px 0px 0px",
    borderRadius: "16px 16px 0 0",
  },
  overlay: {
    backgroundColor: "#00000033",
  },
};

const getSuccessPage = (paymentFor, navigate) => {
  if (paymentFor === "CREDITS") {
    navigate(`/payments/success?for=${paymentFor}`);
  }
};

export { modalStyles, getSuccessPage };
