import CF1 from "../../../assets/icons/cf1.svg";
import CF2 from "../../../assets/icons/cf2.svg";
import CF3 from "../../../assets/icons/cf3.svg";

const CONTENT = [
  {
    id: 1,
    description:
      "Capture more leads and grow your business with Facebook Lead Ads integrated seamlessly with tappi.",
    icon: CF1,
  },
  {
    id: 2,
    description:
      "Rest assured that your Facebook Business Page and customer data are protected with tappi's secure integration.",
    icon: CF2,
  },
  {
    id: 3,
    description:
      "Your control and flexibility matter. You have the option to disconnect your Facebook Business Page from tappi anytime you want.",
    icon: CF3,
  },
];

export default CONTENT;
