import ReactModal from "react-modal";
import React from "react";
import PropTypes from "prop-types";
import Button from "../forms/Button";
import { modalStyles } from "./mpesa.resource";
import { useAppContext } from "../../utils/Contexts/AppContext";

export default function MpesaSdkPrompt({
  showSDKPrompt,
  setShowSDKPrompt,
  confirmPaymentHandler,
  confirmingPayment,
}) {
  const { appTheme } = useAppContext();
  return (
    <ReactModal
      isOpen={showSDKPrompt}
      onRequestClose={() => setShowSDKPrompt(false)}
      style={modalStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className={appTheme}>
        <div className="space-y-2 text-xs mb-4 text-[#566370]">
          <p>
            You should have received an prompt on your MPESA number. Please
            enter your MPESA pin then press confirm below to confirm your
            payment.
          </p>
          <p>
            We will automatically attempt to verify your payment within 5
            minutes if you do not manually confirm.
          </p>
        </div>

        <div className="space-y-2">
          <Button onClick={confirmPaymentHandler}>
            {confirmingPayment ? "Confirming..." : "Confirm"}
          </Button>
          <Button variant="outline" onClick={() => setShowSDKPrompt(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </ReactModal>
  );
}

MpesaSdkPrompt.propTypes = {
  showSDKPrompt: PropTypes.bool.isRequired,
  setShowSDKPrompt: PropTypes.func.isRequired,
  confirmPaymentHandler: PropTypes.func.isRequired,
  confirmingPayment: PropTypes.bool.isRequired,
};
