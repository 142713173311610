import { useEffect, useState } from "react";
import BULK_SMS_PROVIDERS from "./cbs.resources";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";

const useCbs = () => {
  const { user } = useAuth();
  const senderID = user?.sender_id;
  const [bulkSmsProviders, setBulkSmsProviders] = useState(BULK_SMS_PROVIDERS);
  const [showNoSenderIdModal, setShowNoSenderIdModal] = useState(false);

  const handleSetupSenderID = () => {
    const message = `Hi Tappi! 🙌 I want to set up my sender ID. I appreciate your help in getting everything set and ready to go. Thanks!`;
    window.location.href = `https://wa.me/254715412061?text=${encodeURIComponent(
      message
    )}`;
  };

  const handleBulkSmsProviderSelection = (id) => {
    const updatedProviders = bulkSmsProviders.map((provider) =>
      provider.id === id
        ? { ...provider, selected: true }
        : { ...provider, selected: false }
    );
    setBulkSmsProviders(updatedProviders);
  };

  useEffect(() => {
    if (senderID && senderID !== "TAPPI_PROMO") {
      const removeSetupProvider = bulkSmsProviders.filter(
        (bp) => bp.name !== "Set up your Sender ID"
      );
      const configuredProvider = {
        id: bulkSmsProviders.length + 1,
        name: senderID.toUpperCase(),
        icon: user.profile_photo_url,
        selected: true,
        query: user.business_name,
      };
      setBulkSmsProviders([...removeSetupProvider, configuredProvider]);
    }

    if (senderID === "TAPPI_PROMO") {
      const selectedTappiProvider = bulkSmsProviders.map((bp) =>
        bp.name === "TAPPI PROMO" ? { ...bp, selected: true } : bp
      );
      setBulkSmsProviders(selectedTappiProvider);
    }
  }, [senderID]);

  return {
    handleBulkSmsProviderSelection,
    bulkSmsProviders,
    showNoSenderIdModal,
    setShowNoSenderIdModal,
    handleSetupSenderID,
    senderID,
  };
};
export default useCbs;
