import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addFacebookPage } from "../../../utils/requests-v2";

const useAfp = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      facebook_page_id: "",
    },
    validationSchema: Yup.object({
      facebook_page_id: Yup.number().required("This is field is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const response = await addFacebookPage(values);
        if (response?.status === 200) {
          setShowSuccess(true);
        }
      } catch (error) {
        console.error("Error submitting Facebook page ID:", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return { isSubmitting, formik, showSuccess };
};

export default useAfp;
