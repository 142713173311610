import { useEffect, useState } from "react";
import ACCOUNTS from "./ca.resources";
import {
  getCurrentMerchant,
  listConnectedFacebookPages,
} from "../../utils/requests-v2";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";

const useCas = () => {
  const { user } = useAuth();
  const [connectedAccounts, setConnectedAccounts] = useState(ACCOUNTS);

  useEffect(() => {
    Promise.all([getCurrentMerchant(user.id), listConnectedFacebookPages()])
      .then(([merchantResponse, facebookResponse]) => {
        let updatedAccounts = connectedAccounts;

        if (merchantResponse?.till_number || merchantResponse?.store_number) {
          updatedAccounts = updatedAccounts.map((account) =>
            account.id === 2 ? { ...account, status: true } : account
          );
        }

        if (facebookResponse?.data?.total > 0) {
          updatedAccounts = updatedAccounts.map((account) =>
            account.id === 1 ? { ...account, status: true } : account
          );
        }

        setConnectedAccounts(updatedAccounts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return { connectedAccounts };
};

export default useCas;
