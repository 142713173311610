import React from "react";
import PropTypes from "prop-types";
import InputError from "../forms/InputError";

export default function MpesaPhoneNumberPayment({
  mpesaPayMethod,
  inputErrors,
  mpesaPayMethodHandler,
  phoneNumber,
  inputHandler,
  validateInput,
}) {
  return (
    <div className="border rounded-lg px-4 py-2">
      <label
        htmlFor="mpesaNumberRadio"
        className="cursor-pointer p-4"
        title="Pay With Your Phone Number"
      >
        <div className="flex justify-between items-center">
          <h3 className="font-semibold text-sm">Enter Phone Number To Pay</h3>
          <input
            type="radio"
            name="mpesa"
            id="phone_number"
            value="phoneNumber"
            checked={mpesaPayMethod.phone_number}
            onChange={mpesaPayMethodHandler}
            className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-purple-50 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain"
          />
        </div>
      </label>
      {mpesaPayMethod.phone_number && (
        <div>
          <div className="pt-2 px-4 pb-3 bg-[#E7E7E7] text-[#566370] font-semibold text-[0.81rem] mb-4">
            <p>
              Enter your M-Pesa mobile number and click on the Confirm button
              below.
            </p>
          </div>
          <div
            className={`flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2 mb-2 ${
              inputErrors.phoneNumber ? "border-red-500" : ""
            }`}
          >
            <p className="text-[#1A1A1A] font-semibold text-sm">+254</p>
            <svg
              width="1"
              height="24"
              viewBox="0 0 1 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="1" height="24" fill="#E7E7E7" />
            </svg>
            <input
              className="h-full w-full outline-none text-[#566370] text-sm font-semibold appearance-none"
              type="number"
              onChange={inputHandler}
              onBlur={validateInput}
              value={phoneNumber}
              id="phoneNumber"
              required
              minLength={9}
              maxLength={9}
            />
          </div>
          {inputErrors.phoneNumber && (
            <div className="p-2">
              <InputError error={inputErrors.phoneNumber} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

MpesaPhoneNumberPayment.propTypes = {
  mpesaPayMethod: PropTypes.shape({
    phone_number: PropTypes.bool,
    pay_bill: PropTypes.bool,
  }).isRequired,
  inputErrors: PropTypes.shape({
    phoneNumber: PropTypes.string,
    refNumber: PropTypes.string,
  }).isRequired,
  mpesaPayMethodHandler: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  inputHandler: PropTypes.func.isRequired,
  validateInput: PropTypes.func.isRequired,
};
