import React from "react";
import Layout from "../../../../components/layout/Layout";
import AddSmsProviderView from "../../../../components/connect-bulk-sms/add-sms-provider/asp.view";

export default function AddSMSProviderPage() {
  return (
    <Layout
      header
      headerTitle="Add Provider Details"
      backTo="/account/connected-accounts/bulk-sms"
    >
      <AddSmsProviderView />
    </Layout>
  );
}
