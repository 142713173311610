/* eslint-disable react/jsx-props-no-spreading,react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import InputFieldV2 from "../../forms/InputFieldV2";

export default function AddProviderForm({ formik }) {
  return (
    <div className="w-full h-full flex flex-col justify-start items-center space-y-[1.5rem]">
      <InputFieldV2
        label="Sender ID"
        id="sender_id"
        type="text"
        error={formik.errors.sender_id}
        touched={formik.touched.sender_id}
        {...formik.getFieldProps("sender_id")}
      />
      <InputFieldV2
        label="API Key"
        subtitle="This API Key is from your bulk sms provider"
        id="api_key"
        error={formik.errors.api_key}
        touched={formik.touched.api_key}
        {...formik.getFieldProps("api_key")}
      />
    </div>
  );
}

AddProviderForm.propTypes = {
  formik: PropTypes.object.isRequired,
};
