import { useEffect, useState } from "react";
import { listConnectedFacebookPages } from "../../utils/requests-v2";

const useFb = () => {
  const [fetchingFbPages, setFetchingFbPages] = useState(false);
  const [fbPages, setFbPages] = useState([]);

  useEffect(() => {
    const fetchFbPages = async () => {
      setFetchingFbPages(true);
      try {
        const response = await listConnectedFacebookPages();
        if (response?.data?.total > 0) {
          setFbPages(response?.data?.items);
        }
      } catch (error) {
        console.error("Error fetching Facebook pages:", error);
      } finally {
        setFetchingFbPages(false);
      }
    };

    fetchFbPages();
  }, []);

  return { fetchingFbPages, fbPages };
};

export default useFb;
