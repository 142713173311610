import React from "react";
import PropTypes from "prop-types";

export default function MpesaPaybillPayment({
  mpesaPayMethod,
  mpesaPayMethodHandler,
  amount,
  paymentIntent,
}) {
  return (
    <div className="border rounded-lg p-4">
      <label
        htmlFor="mpesaPaybillRadio"
        className="flex justify-between items-center py-4 cursor-pointer"
        title="Pay Via Paybill"
      >
        <h3 className="font-semibold text-sm">Pay Via Paybill</h3>
        <input
          name="paybill"
          type="radio"
          id="mpesaPaybillRadio"
          checked={mpesaPayMethod.pay_bill}
          value="pay_bill"
          onChange={mpesaPayMethodHandler}
          className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-purple-50 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain"
        />
      </label>
      {mpesaPayMethod.pay_bill && (
        <div className="flex flex-col space-y-4">
          <div>
            <p className="pt-2 px-4 pb-3 bg-[#E7E7E7] text-[#566370] font-semibold text-[0.81rem]">
              Follow the steps below. Once you receive a successful reply from
              Mpesa, enter the reference number and click on the Confirm button
              below.
            </p>
          </div>
          <div className="flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2">
            <ul className="h-full outline-none text-[#1A1A1A] text-sm font-normal list-disc ml-3 leading-6">
              <li>Go to M-PESA on your phone</li>
              <li>Select Pay Bill option</li>
              <li>
                Enter Business no. <b>4114987</b>
              </li>
              <li>
                Enter Account no. <b>{paymentIntent}</b>
              </li>
              <li>
                Enter the Amount. <b>KES {amount}</b>
              </li>
              <li>Enter your M-PESA PIN and Send</li>
              <li>You will receive a confirmation SMS from M-PESA</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

MpesaPaybillPayment.propTypes = {
  mpesaPayMethod: PropTypes.shape({
    phone_number: PropTypes.bool,
    pay_bill: PropTypes.bool,
  }).isRequired,
  mpesaPayMethodHandler: PropTypes.func.isRequired,
  paymentIntent: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};
