import noSenderId from "../../assets/icons/connected-accounts/no_sender_id.svg";
import tappiIcon from "../../assets/icons/connected-accounts/TAPPI_FAVICON.png";
// import africaTalking from "../../assets/icons/connected-accounts/africa's_talking.svg";
// import smsLeopard from "../../assets/icons/connected-accounts/sms_leopard.png";
// import uwaziiMobile from "../../assets/icons/connected-accounts/uwazii.png";

const BULK_SMS_PROVIDERS = [
  {
    id: 1,
    name: "TAPPI PROMO",
    icon: tappiIcon,
    selected: false,
    query: "tappi_promo",
  },
  {
    id: 2,
    name: "Set up your Sender ID",
    icon: noSenderId,
    selected: false,
    query: "no_sender_id",
  },
  // {
  //   id: 2,
  //   name: "Africa's Talking",
  //   icon: africaTalking,
  //   selected: false,
  //   query: "africas_talking",
  // },
  // {
  //   id: 3,
  //   name: "SMS Leopard",
  //   icon: smsLeopard,
  //   selected: false,
  //   query: "sms_leopard",
  // },
  // {
  //   id: 4,
  //   name: "Uwazii Mobile",
  //   icon: uwaziiMobile,
  //   selected: false,
  //   query: "uwazii_mobile",
  // },
];

export default BULK_SMS_PROVIDERS;
