import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { validatePhoneNumber } from "../../utils/validators";
import Button from "../forms/Button";
import { useAppContext } from "../../utils/Contexts/AppContext";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import LabelledInput from "../forms/LabelledInput";
import GoogleLogo from "../../assets/icons/flat-color-icons_google.svg";
import FacebookLogo from "../../assets/icons/logos_facebook.svg";
import AppleLogo from "../../assets/icons/ic_baseline-apple.svg";
import PasswordInput from "../forms/PasswordInput";
import formatPhoneNumber from "../../utils/formatPhoneNumber";

export default function LoginForm() {
  const navigate = useNavigate();
  const appContext = useAppContext();

  const [usernameInputErrors, setUsernameInputErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { login } = useAuth();

  const { register, getValues, control } = useForm({
    defaultValues: {
      password: "",
      username: "",
    },
  });

  async function handleSubmit(event) {
    event.preventDefault();
    const username = getValues("username");
    if (!username) {
      setUsernameInputErrors({ username: "Phone number or email is required" });
      return;
    }

    if (
      !validatePhoneNumber(username, appContext.country) &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)
    ) {
      setUsernameInputErrors({
        username: "Invalid phone number or email address",
      });
      return;
    }

    try {
      // Ensure the username is formatted correctly if it's made of digits
      const user = /^\d+$/.test(username)
        ? formatPhoneNumber(username)
        : username;
      setIsLoading(true);
      const password = getValues("password");
      const loginRes = await login({ username: user, password });
      setIsLoading(false);
      if (loginRes.merchants?.items.length > 1) {
        navigate("/join/select-account");
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail || "Merchant not found");
      setIsLoading(false);
    } finally {
      setUsernameInputErrors({});
    }
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col h-full">
      <div>
        <p className="mb-4 text-[#566370]">
          Enter your registered phone number or email address & password to
          continue.
        </p>
      </div>

      <div>
        <LabelledInput
          name="username"
          type="text"
          placeholder="username"
          label="Email Address or Phone Number"
          control={control}
          errorText={usernameInputErrors?.username}
          errors={usernameInputErrors}
          register={register}
          rules={{ required: true }}
        />
      </div>

      <div className="mb-6">
        <PasswordInput
          id="passwordInput"
          placeholder="Password"
          label="Password"
          name="password"
          register={register}
          rules={{ required: true }}
          type="password"
        />
      </div>

      <div className="text-[#4B1D8F] block grow text-right">
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>

      <div className="text-center lg:text-left">
        <Button type="submit" isLoading={isLoading} disabled={isLoading}>
          Login
        </Button>
      </div>
      <div className="my-8">
        <div className="flex gap-4 flex items-center my-8 text-center">
          <hr className="w-full" />
          <p className="w-full">or continue with</p>
          <hr className="w-full" />
        </div>
        <div className="w-full flex flex-row items-center justify-center gap-8">
          <button
            type="button"
            disabled
            className="rounded rounded-md border border-[#D9D9D9] p-4"
          >
            <img src={GoogleLogo} alt="Continue With Google" />
          </button>
          <button
            type="button"
            disabled
            className="rounded rounded-md border border-[#D9D9D9] p-4"
          >
            <img src={FacebookLogo} alt="Continue With Facebook" />
          </button>
          <button
            type="button"
            disabled
            className="rounded rounded-md border border-[#D9D9D9] p-4"
          >
            <img src={AppleLogo} alt="Continue With Apple" />
          </button>
        </div>
      </div>
    </form>
  );
}
