// eslint-disable-next-line import/prefer-default-export
export const generatePaymentData = (paymentData) => {
  switch (paymentData.payment_reason) {
    case "WALLET_TOP_UP":
      return {
        amount: paymentData.amount,
        payment_reason: paymentData.payment_reason,
      };
    case "AD_CREATION":
      return {
        amount: paymentData.amount,
        payment_reason: paymentData.payment_reason,
        ad_id: paymentData.ad_id,
      };
    default:
      return {
        amount: paymentData.amount,
        payment_reason: paymentData.payment_reason,
      };
  }
};
