import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import CreateAdsIcon from "../assets/icons/create-ads-icon.svg";
import SendOffersIcon from "../assets/icons/send-offers-icon.svg";
import RequestPaymentIcon from "../assets/icons/request-payment-icon.svg";
import ShareWebPageIcon from "../assets/icons/share-webpage-icon.svg";

function QuickActionTiles({ showDialogue }) {
  return (
    <div className="w-full h-[6.25rem] flex flex-row justify-evenly items-center">
      {/* <Link to="/ads/create" className="flex flex-col items-center space-y-2.5"> */}
      {/*  <div className="h-16 w-16 flex justify-center items-center bg-createAds cursor-pointer rounded-2xl"> */}
      {/*    <img src={CreateAdsIcon} alt="Create Ad" /> */}
      {/*  </div> */}
      {/*  <div className="flex flex-col justify-center items-center"> */}
      {/*    <p className="text-xs font-medium">{`Create Ad`.split(" ")[0]}</p> */}
      {/*    <p className="text-xs font-medium">{`Create Ad`.split(" ")[1]}</p> */}
      {/*  </div> */}
      {/* </Link> */}
      <Link
        to="/create-offers"
        className="flex flex-col items-center space-y-2.5"
      >
        <div className="h-16 w-16 flex justify-center items-center bg-sendOffer cursor-pointer rounded-2xl">
          <img src={SendOffersIcon} alt="Send offer" />
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="text-xs font-medium">{`Send offer`.split(" ")[0]}</p>
          <p className="text-xs font-medium">{`Send offer`.split(" ")[1]}</p>
        </div>
      </Link>
      <Link
        to="/request-payment"
        className="flex flex-col items-center space-y-2.5 hidden thryve:block"
      >
        <div className="h-16 w-16 flex justify-center items-center bg-[rgba(0,_135,_83,_0.12)] cursor-pointer rounded-2xl">
          <img src={RequestPaymentIcon} alt="Request Payment" />
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="text-xs font-medium">
            {`Request payment`.split(" ")[0]}
          </p>
          <p className="text-xs font-medium">
            {`Request payment`.split(" ")[1]}
          </p>
        </div>
      </Link>
      <button
        type="button"
        onClick={() => showDialogue(true, "Share Webpage")}
        className="flex flex-col items-center space-y-2.5"
      >
        <div className="h-16 w-16 flex justify-center items-center bg-shareWebpage cursor-pointer rounded-2xl">
          <img src={ShareWebPageIcon} alt="Share Webpage" />
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="text-xs font-medium">{`Share Webpage`.split(" ")[0]}</p>
          <p className="text-xs font-medium">{`Share Webpage`.split(" ")[1]}</p>
        </div>
      </button>
    </div>
  );
}

QuickActionTiles.propTypes = {
  showDialogue: PropTypes.func.isRequired,
};

export default QuickActionTiles;
