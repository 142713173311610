import React from "react";
import Layout from "../layout/V2/Layout";
import Button from "../forms/Button";
import useMpesaPayment from "./useMpesaPayment";
import MpesaPhoneNumberPayment from "./mpesa.phoneNumber-payment";
import MpesaPaybillPayment from "./mpesa.paybill-payment";
import MpesaSdkPrompt from "./mpesa.sdk-prompt";

export default function MpesaView() {
  const {
    showSDKPrompt,
    loadingIntent,
    confirmingPayment,
    pushSDKPaymentPrompt,
    confirmPaymentHandler,
    paymentAmount,
    inputErrors,
    inputHandler,
    mpesaPayMethod,
    mpesaPayMethodHandler,
    phoneNumber,
    user,
    setShowSDKPrompt,
    validateInput,
    paymentIntent,
  } = useMpesaPayment();

  return (
    <Layout
      header
      headerTitle={
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-normal text-gray-500 text-center">
            Make MPESA Payment
          </p>
          <h1 className="text-base">Pay For Your Credits</h1>
        </div>
      }
    >
      <MpesaSdkPrompt
        showSDKPrompt={showSDKPrompt}
        setShowSDKPrompt={setShowSDKPrompt}
        confirmPaymentHandler={confirmPaymentHandler}
        confirmingPayment={confirmingPayment}
      />
      <div className="py-20">
        <div className="text-center mx-auto mb-6">
          <p className="text-sm font-medium mt-2">You are about to pay</p>
          <p className="text-xl font-semibold text-[#1A1A1A] mt-2">
            KES {paymentAmount}
          </p>
        </div>
        <fieldset
          className="flex flex-col h-full px-4 space-y-4 md:w-[768px] mx-auto"
          disabled={loadingIntent}
          aria-busy={loadingIntent}
        >
          <MpesaPhoneNumberPayment
            mpesaPayMethod={mpesaPayMethod}
            inputErrors={inputErrors}
            mpesaPayMethodHandler={mpesaPayMethodHandler}
            phoneNumber={phoneNumber}
            inputHandler={inputHandler}
            validateInput={validateInput}
          />
          <MpesaPaybillPayment
            mpesaPayMethod={mpesaPayMethod}
            mpesaPayMethodHandler={mpesaPayMethodHandler}
            userID={user?.id}
            amount={paymentAmount}
            paymentIntent={paymentIntent}
          />
          <Button
            type="button"
            onClick={
              mpesaPayMethod.pay_bill
                ? confirmPaymentHandler
                : pushSDKPaymentPrompt
            }
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {confirmingPayment
              ? mpesaPayMethod.pay_bill
                ? "Processing Payment..."
                : "Sending SDK Push..."
              : mpesaPayMethod.pay_bill
              ? "I have made payment"
              : "Send SDK Prompt"}
          </Button>
        </fieldset>
      </div>
    </Layout>
  );
}
