import React from "react";
import PropTypes from "prop-types";
import ConnectedAccountOption from "./ca.option";

export default function ConnectedAccountOptions({ options }) {
  return (
    <div className="w-full h-full flex flex-col justify-start items-center space-y-[1.5rem]">
      {options.map((option) => (
        <ConnectedAccountOption option={option} key={option.id} />
      ))}
    </div>
  );
}

ConnectedAccountOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      status: PropTypes.bool.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};
