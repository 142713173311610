import React from "react";
import Layout from "../../../../components/layout/Layout";
import AddFacebookPageView from "../../../../components/connect-facebook/add-facebook-page/afp.view";

export default function AddFacebookPage() {
  return (
    <Layout
      header
      headerTitle="Connect to Facebook"
      backTo="/account/connected-accounts"
    >
      <AddFacebookPageView />
    </Layout>
  );
}
