import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

const useAsp = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      sender_id: "",
      api_key: "",
    },
    validationSchema: Yup.object({
      sender_id: Yup.string().required("This is field is required"),
      api_key: Yup.string().required("This is field is required"),
    }),
    onSubmit: async (values) => {
      setShowSuccess(true);
      console.log(values);
    },
  });

  return { isSubmitting, formik, setSubmitting, showSuccess, setShowSuccess };
};

export default useAsp;
